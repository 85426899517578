import React from "react";
import WebsiteSectionWrapper from "../layout/WebsiteSectionWrapper";

function ContentLayout({ title, children }) {
  return (
    <WebsiteSectionWrapper className="first-child">
      {title && <h4>{title}</h4>}
      {children}
    </WebsiteSectionWrapper>
  );
}

export default ContentLayout;
