import React from "react";
import { Outlet, Link } from "react-router-dom";
import WebsiteSectionWrapper from "./WebsiteSectionWrapper";
import WebsiteHeaderNav from "../components/nav/WebsiteHeaderNav";

const slogans = [
  "Your last-minute lifesaver",
  "By Freelancers; For Freelancers",
  "Set once; Benefit everyday",
];

function WebsiteLayout() {
  return (
    <div className="website-layout">
      <div className="website-header">
        <WebsiteSectionWrapper>
          <div className="website-header__logo">
            <Link to="/">Freelancers Directory</Link>
            <span className="website-header__slogan">
              {slogans[Math.floor(Math.random() * slogans.length)]}
            </span>
          </div>
          <WebsiteHeaderNav />
        </WebsiteSectionWrapper>
      </div>
      <div className="website-page-content">
        <Outlet />
      </div>
      <div className="website-footer">
        <WebsiteSectionWrapper>
          <div>
            <div className="website-footer__menu">
              <div>
                <Link
                  to="/content/services/"
                  className="website-footer__menu__header"
                >
                  Services
                </Link>
                <ul className="no-style">
                  <li>
                    <Link
                      to="/content/services/membership"
                      className="website-footer__menu__item"
                    >
                      Membership
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/content/services/posting-an-advert"
                      className="website-footer__menu__item"
                    >
                      Post an Advert
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/content/services/pricing"
                      className="website-footer__menu__item"
                    >
                      Pricing
                    </Link>
                  </li>
                </ul>
              </div>

              <div>
                <Link
                  to="/content/legal/"
                  className="website-footer__menu__header"
                >
                  Legal
                </Link>
                <ul className="no-style">
                  <li>
                    <Link
                      to="/content/legal/terms-of-service"
                      className="website-footer__menu__item"
                    >
                      Terms of Service
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/content/legal/privacy-policy"
                      className="website-footer__menu__item"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/content/legal/web-cookies"
                      className="website-footer__menu__item"
                    >
                      Web Cookies
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/content/legal/google-analytics"
                      className="website-footer__menu__item"
                    >
                      Google Analytics
                    </Link>
                  </li>
                </ul>
              </div>

              <div>
                <Link
                  to="/content/resources/"
                  className="website-footer__menu__header"
                >
                  Resources
                </Link>
                <ul className="no-style">
                  <li>
                    <Link
                      to="/content/resources/how-does-it-work"
                      className="website-footer__menu__item"
                    >
                      How does it work?
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/content/resources/frequently-asked-questions"
                      className="website-footer__menu__item"
                    >
                      F.A.Q.
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/content/resources/supported-jobs"
                      className="website-footer__menu__item"
                    >
                      Supported Jobs
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/content/resources/supported-locations"
                      className="website-footer__menu__item"
                    >
                      Supported Locations
                    </Link>
                  </li>
                </ul>
              </div>

              <div>
                <Link
                  to="/content/help/"
                  className="website-footer__menu__header"
                >
                  Help
                </Link>
                <ul className="no-style">
                  <li>
                    <Link
                      to="/content/help/support"
                      className="website-footer__menu__item"
                    >
                      Support
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/content/help/feedback"
                      className="website-footer__menu__item"
                    >
                      Feedback
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/content/help/contact-us"
                      className="website-footer__menu__item"
                    >
                      Contact
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/content/help/about-us"
                      className="website-footer__menu__item"
                    >
                      About
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div>
              <div className="website-footer__logo">
                Freelancers Directory
                <span className="website-footer__copyright">
                  Copyright &copy; 2024 All Rights Reserved.
                </span>
              </div>
            </div>
          </div>
        </WebsiteSectionWrapper>
      </div>
    </div>
  );
}

export default WebsiteLayout;
