import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

// import "./utils/score_results";

export const firebaseConfig = {
  apiKey: "AIzaSyA3RdLzAVL1iM5EtmR45d0a0818yyqA8-U",
  authDomain: "freelancers-directory.firebaseapp.com",
  projectId: "freelancers-directory",
  storageBucket: "freelancers-directory.appspot.com",
  messagingSenderId: "288622735312",
  appId: "1:288622735312:web:0a96bf1f9169cb853ad3d5",
  measurementId: "G-WRGR55MCNT",
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAnalytics = getAnalytics(firebaseApp);
export const firestoreDB = getFirestore(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);
// connectAuthEmulator(firebaseAuth, "http://127.0.0.1:9099");
export const googleAuth = new GoogleAuthProvider();
