import React from "react";
import ContentLayout from "../../layout/ContentLayout";

function ContactUsContent() {
  return (
    <ContentLayout title="Contact Us">
      <p>We are still working on this page! :)</p>
    </ContentLayout>
  );
}

export default ContactUsContent;
