import React from "react";
import ContentLayout from "../../layout/ContentLayout";

function MembershipContent() {
  return (
    <ContentLayout title="Membership">
      <p>We are still working on this page! :)</p>
    </ContentLayout>
  );
}

export default MembershipContent;
