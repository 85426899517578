import React from "react";
import IndexComponent from "../IndexComponent";

function LegalContentIndex() {
  const contentList = [
    { label: "Terms of Service", link: "/content/legal/terms-of-service" },
    { label: "Privacy Policy", link: "/content/legal/privacy-policy" },
    { label: "Web Cookies", link: "/content/legal/web-cookies" },
    { label: "Google Analytics", link: "/content/legal/google-analytics" },
  ];

  return <IndexComponent title="Legal" contentList={contentList} />;
}

export default LegalContentIndex;
