export const allLangs = [
  "Abkhaz",
  "Acehnese",
  "Acholi dialect",
  "Afar",
  "Afrikaans",
  "Albanian",
  "Alur",
  "Amharic",
  "Arabic",
  "Armenian",
  "Assamese",
  "Avar",
  "Awadhi",
  "Aymara",
  "Azerbaijani",
  "Balinese",
  "Balochi",
  "Bambara",
  "Bashkir",
  "Basque",
  "Batak Karo",
  "Batak Simalungun",
  "Toba Batak",
  "Belarusian",
  "Bemba",
  "Bengali",
  "Betawi",
  "Bhojpuri",
  "Central Bikol",
  "Bosnian",
  "Breton",
  "Bulgarian",
  "Buryat",
  "Cantonese",
  "Catalan",
  "Cebuano",
  "Chamorro",
  "Chechen",
  "Chewa",
  "Chinese - Simplified",
  "Chinese - Traditional",
  "Chuukese",
  "Chuvash",
  "Corsican",
  "Crimean Tatar",
  "Croatian",
  "Czech",
  "Danish",
  "Dari",
  "Maldivian",
  "Dinka",
  "Dogri",
  "Dutch",
  "Dyula",
  "Dzongkha",
  "English",
  "Esperanto",
  "Estonian",
  "Ewe",
  "Faroese",
  "Fijian",
  "Filipino",
  "Finnish",
  "Fon",
  "French",
  "West Frisian",
  "Friulian",
  "Fula",
  "Ga",
  "Galician",
  "Georgian",
  "German",
  "Greek",
  "Guarani",
  "Gujarati",
  "Haitian Creole",
  "Hakha Chin",
  "Hausa",
  "Hawaiian",
  "Hebrew",
  "Hiligaynon",
  "Hindi",
  "Hmong",
  "Hungarian",
  "Hunsrik",
  "Iban",
  "Icelandic",
  "Igbo",
  "Ilocano",
  "Indonesian",
  "Irish",
  "Italian",
  "Jamaican Patois",
  "Japanese",
  "Javanese",
  "Jingpo",
  "West Greenlandic",
  "Kannada",
  "Kanuri",
  "Kapampangan",
  "Kazakh",
  "Khasi",
  "Khmer",
  "Kiga",
  "Kongo",
  "Kinyarwanda",
  "Kituba",
  "Kokborok",
  "Komi",
  "Konkani",
  "Korean",
  "Krio",
  "Kurdish - Kurmanji",
  "Kurdish - Sorani",
  "Kyrgyz",
  "Lao",
  "Latgalian",
  "Latin",
  "Latvian",
  "Ligurian",
  "Limburgish",
  "Lingala",
  "Lithuanian",
  "Lombard",
  "Luganda",
  "Dholuo",
  "Luxembourgish",
  "Macedonian",
  "Madurese",
  "Maithili",
  "Makassarese",
  "Malagasy",
  "Malay",
  "Malay - Jawi script",
  "Malayalam",
  "Maltese",
  "Mam",
  "Manx",
  "Marathi",
  "Marshallese",
  "Marwari",
  "Mauritian Creole",
  "Meadow Mari",
  "Meitei",
  "Minangkabau",
  "Mizo",
  "Mongolian",
  "Burmese",
  "Huasteca Nahuatl",
  "Ndau dialect",
  "Southern Ndebele",
  "Newar",
  "Nepali",
  "Norwegian",
  "Nuer",
  "Occitan",
  "Odia",
  "Oromo",
  "Ossetian",
  "Pangasinan",
  "Papiamento",
  "Pashto",
  "Persian",
  "Polish",
  "Portuguese - Brazilian",
  "Portuguese - European",
  "Punjabi - Gurmukhi",
  "Punjabi - Shahmukhi",
  "Southern Quechua",
  "Romani",
  "Romanian",
  "Kirundi",
  "Russian",
  "Samoan",
  "Sango",
  "Sanskrit",
  "Santali",
  "Scottish Gaelic",
  "Northern Sotho",
  "Serbian",
  "Sotho",
  "Seychellois Creole",
  "Shan",
  "Shona",
  "Sicilian",
  "Silesian",
  "Sindhi",
  "Sinhala",
  "Slovak",
  "Slovene",
  "Somali",
  "Spanish",
  "Sundanese",
  "Susu",
  "Swahili",
  "Swazi",
  "Swedish",
  "Tahitian",
  "Tajik",
  "Berbers/Tamazight",
  "Berbers/Tamazight - Tifinagh",
  "Tamil",
  "Tatar",
  "Telugu",
  "Tetum",
  "Thai",
  "Lhasa Tibetan",
  "Tigrinya",
  "Tiv",
  "Tok Pisin",
  "Tongan",
  "Tsonga",
  "Tswana",
  "Tulu",
  "Tumbuka",
  "Turkish",
  "Turkmen",
  "Tuvan",
  "Twi",
  "Udmurt",
  "Ukrainian",
  "Urdu",
  "Uyghur",
  "Uzbek",
  "Venda",
  "Venetian",
  "Vietnamese",
  "Waray",
  "Welsh",
  "Wolof",
  "Xhosa",
  "Yakut",
  "Yiddish",
  "Yoruba",
  "Yucatec Maya",
  "Zapotecs",
  "Zulu",
];
