import React from "react";
import { Link } from "react-router-dom";

function WebsiteHeaderNav() {
  return (
    <ul className="website-header-nav">
      {/* <li>
        <Link to="/">Home</Link>
      </li> */}
      <li>
        <Link to="/app">Dashboard</Link>
      </li>
    </ul>
  );
}

export default WebsiteHeaderNav;
