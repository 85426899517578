import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../utils/context";
import Form, {
  FormButtons,
  FormField,
  FormMessage,
} from "../../components/form/Form";
import Button from "../../components/form/Button";
import { CardStep } from "../Card";

function RolesForm({ roles, card }) {
  const { user, setUser } = useContext(UserContext);

  const [userRoles, setUserRoles] = useState(
    user[card] || {
      default_role: {},
    }
  );

  const allRoles = roles;

  useEffect(() => {
    updateRoles(userRoles);
  }, [userRoles]);

  function updateRoles(value) {
    setUser((prev) => {
      const update = {
        ...prev,
      };

      update[card] = value;

      return update;
    });
  }

  function getAllIndustries() {
    return Object.keys(allRoles);
  }

  function getAllIndustryRoles(industry) {
    return Object.keys(allRoles[industry].roles);
  }

  function getIndustryRole(industry, role) {
    return allRoles[industry].roles[role];
  }

  function getAllIndustryRoleSpecialities(industry, role) {
    return Object.keys(allRoles[industry].roles[role].speciality);
  }

  function getIndustryRoleSpeciality(industry, role, spec) {
    return allRoles[industry].roles[role].speciality[spec];
  }

  function getAddRoleButton() {
    if (Object.keys(userRoles).length < 5 && !userRoles.default_role) {
      return (
        <Button
          label="Add Another Role"
          role="accent"
          onClick={handleAddRole}
        />
      );
    }

    return null;
  }

  function handleAddRole(e) {
    e.preventDefault();

    setUserRoles((prev) => {
      const update = { ...prev };

      if (update.default_role) {
        delete update.default_role;
      }
      const roleId = Date.now();
      update[roleId] = {};

      return update;
    });
  }

  function getIndustryInput(roleId) {
    const ind = getAllIndustries();

    function handleOnChange({ target }, roleId) {
      const { value } = target;

      setUserRoles((prev) => {
        let update = { ...prev };

        if (value == "") {
          if (Object.keys(userRoles).length > 1) {
            update[roleId] = {};
          } else {
            update = {
              default_role: {},
            };
          }
          return update;
        }

        if (update.default_role) {
          roleId = Date.now();
          delete update.default_role;
        }

        if (!update[roleId]) {
          update[roleId] = {};
        }

        update[roleId].industry = {
          id: value,
        };

        return update;
      });
    }

    return (
      <select
        name="industry"
        value={userRoles[roleId]?.industry?.id}
        onChange={(e) => handleOnChange(e, roleId)}
      >
        <option value="">-- Select --</option>
        {ind.map((industry) => (
          <option value={allRoles[industry].id}>
            {allRoles[industry].label}
          </option>
        ))}
      </select>
    );
  }

  function getRoleInput(roleId) {
    if (!userRoles[roleId]) {
      return null;
    }

    const { industry } = userRoles[roleId];

    if (!industry) {
      return null;
    }

    const { role } = industry;

    const industryRoles = getAllIndustryRoles(industry.id);

    function handleOnChange({ target }, roleId) {
      const { value } = target;

      setUserRoles((prev) => {
        const update = { ...prev };

        if (!update[roleId].industry?.role) {
          update[roleId].industry.role = {};
        }

        update[roleId].industry.role = {
          id: value,
          specs: {},
        };

        return update;
      });
    }

    return (
      <select
        value={userRoles[roleId].industry?.role?.id}
        onChange={(e) => handleOnChange(e, roleId)}
      >
        <option value="">-- Select --</option>
        {industry.id &&
          industryRoles.map((roleName) => (
            <option value={getIndustryRole(industry.id, roleName).id}>
              {getIndustryRole(industry.id, roleName).label}
            </option>
          ))}
      </select>
    );
  }

  function handleSpecialitySelection(
    { target },
    roleId,
    industryId,
    roleNameId
  ) {
    const spec = target.value;
    const isChecked = target.checked;

    setUserRoles((prev) => {
      const update = {
        ...prev,
      };

      if (!update[roleId].industry.role?.specs) {
        update[roleId].industry.role.specs = {};
      }

      update[roleId].industry.role.specs[spec] = {
        checked: isChecked,
        level: "--",
      };

      return update;
    });
  }

  function isSpecChecked(roleId, spec) {
    if (userRoles[roleId]?.industry?.role?.specs) {
      return userRoles[roleId].industry.role.specs[spec]?.checked;
    }

    return false;
  }

  function getSpecialityInput(spec, roleId) {
    const { industry } = userRoles[roleId];
    const { role } = industry;

    const isChecked = isSpecChecked(roleId, spec);

    function handleLevelChange({ target }) {
      const { value } = target;

      setUserRoles((prev) => {
        const update = { ...prev };
        update[roleId].industry.role.specs[spec].level = value;
        return update;
      });
    }

    const levelInput = (
      <select
        value={userRoles[roleId].industry.role?.specs[spec]?.level || "--"}
        defaultValue="--"
        name={`level`}
        onChange={handleLevelChange}
      >
        <option value="">-- Level --</option>
        <option value="1">Beginner</option>
        <option value="2">Advanced</option>
        <option value="3">Expert</option>
      </select>
    );

    const checkboxId = userRoles[roleId].industry.role.id + "_" + spec;

    return (
      <div className="roles-card__specialities__item">
        <div>
          <input
            type="checkbox"
            value={spec}
            id={checkboxId}
            checked={isChecked}
            onChange={(e) =>
              handleSpecialitySelection(e, roleId, industry.id, role.id)
            }
          />
          <label htmlFor={checkboxId}>
            {` ${getIndustryRoleSpeciality(industry.id, role.id, spec).label}`}
          </label>
        </div>
        {isChecked && levelInput}
      </div>
    );
  }

  function handleRemoveRole(e, roleId) {
    e.preventDefault();

    setUserRoles((prev) => {
      let update = { ...prev };

      if (update[roleId]) {
        delete update[roleId];
      }

      return update;
    });
  }

  function getRemoveRoleButton(roleId) {
    if (Object.keys(userRoles).length > 1) {
      return (
        <a
          href="#"
          className="delete-role-button"
          onClick={(e) => handleRemoveRole(e, roleId)}
        >
          [delete]
        </a>
      );
    }
  }

  function getRole(roleId, index) {
    const lastItem = index == Object.keys(userRoles).length - 1;

    return (
      <Form className={`roles-card ${lastItem && "last-child"}`}>
        <FormField
          label={[<span>Role #{index + 1}</span>, getRemoveRoleButton(roleId)]}
          input={getIndustryInput(roleId)}
        />
        <FormField label="" input={getRoleInput(roleId)} />
        {userRoles[roleId]?.industry?.role?.id && (
          <div className="roles-card__specialities">
            <div className="roles-card__specialities__title">Role:</div>
            <br />
            {getAllIndustryRoleSpecialities(
              userRoles[roleId]?.industry?.id,
              userRoles[roleId]?.industry?.role?.id
            ).map((spec) => getSpecialityInput(spec, roleId))}
          </div>
        )}
      </Form>
    );
  }

  function createOrderedList(obj) {
    return Object.keys(obj).sort((a, b) => {
      return a - b;
    });
  }

  const rolesInOrder = createOrderedList(userRoles);

  return (
    <CardStep>
      {rolesInOrder.map((roleId, index) => getRole(roleId, index))}
      <br />
      <Form className="first-child">
        <FormButtons>{getAddRoleButton()}</FormButtons>
      </Form>
    </CardStep>
  );
}

export default RolesForm;
