import React from "react";
import ContentLayout from "../../layout/ContentLayout";

function SupportContent() {
  return (
    <ContentLayout title="Support">
      <p>We are still working on this page! :)</p>
    </ContentLayout>
  );
}

export default SupportContent;
