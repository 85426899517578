import React, { useState, useEffect } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { firebaseAuth } from "../firebase.js";
import { loadSignedInUserData } from "../utils/auth.js";
import { UserContext, getDefatulUserContextValue } from "../utils/context.js";

function AppLayout() {
  const [user, setUser] = useState(getDefatulUserContextValue());
  const [loading, setLoading] = useState(!user.profileLoaded);
  const [showAppMenu, setShowAppMenu] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(firebaseAuth, async (authUser) => {
      if (authUser?.accessToken && !user.profileLoaded) {
        await loadSignedInUserData(authUser.accessToken, setUser);

        setUser((prev) => {
          const update = {
            ...prev,
            emailVerified: authUser?.emailVerified,
          };

          if (!update?.contact_details?.name) {
            update.contact_details.name = authUser?.displayName;
          }

          // if (!update?.contact_details?.email.address) {
          //   update.contact_details.email = {
          //     address: authUser?.email,
          //     verified: authUser?.emailVerified,
          //   };
          // }

          return update;
        });

        setLoading(false);
      }
    });
  }, []);

  onAuthStateChanged(firebaseAuth, (authUser) => {
    if (!authUser || !authUser.uid || !authUser.accessToken) {
      setUser(getDefatulUserContextValue());
      navigate("/sign-in");
    }
  });

  // console.log("app layout > user:", user);

  function toggleAppMenu() {
    setShowAppMenu(!showAppMenu);
  }

  function getAppMenu(largeScreen) {
    return (
      <ul className={`app-menu ${largeScreen && "app-menu--large-screen"}`}>
        <li>
          <Link to="/app" onClick={() => setShowAppMenu(false)}>
            Dashboard
          </Link>
        </li>
        <li>
          <Link to="/app/settings" onClick={() => setShowAppMenu(false)}>
            Settings
          </Link>
        </li>
        <li>
          <Link to="/sign-out" onClick={() => setShowAppMenu(false)}>
            Sign out
          </Link>
        </li>
      </ul>
    );
  }

  return (
    <div className="app-layout">
      <UserContext.Provider value={{ user, setUser }}>
        <div className="app-header">
          <nav>
            <Link to="/">Home</Link>

            {!showAppMenu ? (
              <span
                onClick={toggleAppMenu}
                className="material-symbols-outlined icon-app-menu"
              ></span>
            ) : (
              <span
                onClick={toggleAppMenu}
                className="material-symbols-outlined icon-app-menu--close"
              ></span>
            )}
            {getAppMenu(true)}
          </nav>
          {showAppMenu && getAppMenu(false)}
        </div>
        <div className="app-page-content">
          {user.profileLoaded && !user.emailVerified && (
            <span>--- Your login email is not verified yet!</span>
          )}
          {loading ? <h4>Loading...</h4> : <Outlet />}
        </div>
        <div className="app-footer"></div>
      </UserContext.Provider>
    </div>
  );
}

export default AppLayout;
