import React from "react";
import ContentLayout from "../../layout/ContentLayout";

function SupportedLocationsContent() {
  return (
    <ContentLayout title="Supported Locations">
      <p>We are still working on this page! :)</p>
    </ContentLayout>
  );
}

export default SupportedLocationsContent;
