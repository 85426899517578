import React from "react";

const WebsiteSectionWrapper = ({ children, colour, className }) => {
  return (
    <div
      className={
        colour
          ? `website-section-wrapper website-section-wrapper--${colour} ${className}`
          : `website-section-wrapper ${className}`
      }
    >
      {children}
    </div>
  );
};

export default WebsiteSectionWrapper;
