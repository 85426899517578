import React from "react";
import ContentLayout from "../../layout/ContentLayout";

function SupportedJobsContent() {
  return (
    <ContentLayout title="Supported Jobs">
      <p>We are still working on this page! :)</p>
    </ContentLayout>
  );
}

export default SupportedJobsContent;
