import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./styles/index.scss";
import WebsiteLayout from "./layout/WebsiteLayout";
import AppLayout from "./layout/AppLayout";
import HomePage from "./pages/HomePage";
import ErrorPage from "./pages/ErrorPage";
import SignUpPage from "./pages/SignUpPage";
import SignInPage from "./pages/SignInPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import PasswordlessSignInPage from "./pages/PasswordlessSignInPage";
import DashboardPage from "./pages/DashboardPage";
import SettingsPage from "./pages/SettingsPage";
import CheckYourEmailPage from "./pages/CheckYourEmailPage";
import SignOutPage from "./pages/SignOutPage";
import ServicesContentIndex from "./content/services/ServicesContentIndex";
import MembershipContent from "./content/services/MembershipConten";
import PostAnAdvertContent from "./content/services/PostAnAdvertContent";
import PricingContent from "./content/services/PricingContent";
import LegalContentIndex from "./content/legal/LegalContentIndex";
import TermsOfServiceContent from "./content/legal/TermsOfServiceContent";
import PrivacyPolicyContent from "./content/legal/PrivacyPolicyContent";
import WebCookiesContent from "./content/legal/WebCookiesContent";
import GoogleAnalyticsContent from "./content/legal/GoogleAnalyticsContent";
import ResourcesContentIndex from "./content/resources/ResourcesContentIndex";
import HowDoesItWorkContent from "./content/resources/HowDoesItWorkContent";
import FAQContent from "./content/resources/FAQContent";
import SupportedJobsContent from "./content/resources/SupportedJobsContent";
import SupportedLocationsContent from "./content/resources/SupportedLocationsContent";
import HelpContentIndex from "./content/help/HelpContentIndex";
import SupportContent from "./content/help/SupportContent";
import FeedbackContent from "./content/help/FeedbackContent";
import ContactUsContent from "./content/help/ContactUsContent";
import AboutUsContent from "./content/help/AboutUsContent";

const router = createBrowserRouter([
  {
    path: "/",
    element: <WebsiteLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "sign-up",
        element: <SignUpPage />,
      },
      {
        path: "sign-in",
        element: <SignInPage />,
      },
      {
        path: "forgot-password",
        element: <ForgotPasswordPage />,
      },
      {
        path: "passwordless",
        element: <PasswordlessSignInPage />,
      },
      {
        path: "check-your-email",
        element: <CheckYourEmailPage />,
      },
      {
        path: "sign-out",
        element: <SignOutPage />,
      },
      {
        path: "content",
        children: [
          {
            path: "services",
            element: <ServicesContentIndex />,
          },
          {
            path: "services/membership",
            element: <MembershipContent />,
          },
          {
            path: "services/posting-an-advert",
            element: <PostAnAdvertContent />,
          },
          {
            path: "services/pricing",
            element: <PricingContent />,
          },
          {
            path: "legal",
            element: <LegalContentIndex />,
          },
          {
            path: "legal/terms-of-service",
            element: <TermsOfServiceContent />,
          },
          {
            path: "legal/privacy-policy",
            element: <PrivacyPolicyContent />,
          },
          {
            path: "legal/web-cookies",
            element: <WebCookiesContent />,
          },
          {
            path: "legal/google-analytics",
            element: <GoogleAnalyticsContent />,
          },
          {
            path: "resources",
            element: <ResourcesContentIndex />,
          },
          {
            path: "resources/how-does-it-work",
            element: <HowDoesItWorkContent />,
          },
          {
            path: "resources/frequently-asked-questions",
            element: <FAQContent />,
          },
          {
            path: "resources/supported-jobs",
            element: <SupportedJobsContent />,
          },
          {
            path: "resources/supported-locations",
            element: <SupportedLocationsContent />,
          },
          {
            path: "help",
            element: <HelpContentIndex />,
          },
          {
            path: "help/support",
            element: <SupportContent />,
          },
          {
            path: "help/feedback",
            element: <FeedbackContent />,
          },
          {
            path: "help/contact-us",
            element: <ContactUsContent />,
          },
          {
            path: "help/about-us",
            element: <AboutUsContent />,
          },
        ],
      },
    ],
  },
  {
    path: "/app",
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <DashboardPage />,
      },
      {
        path: "settings",
        element: <SettingsPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
