import React from "react";
import UserAccountSettingsPage from "./UserAccountSettingsPage";
import MembershipSettingsPage from "./MembershipSettingsPage";

function SettingsPage() {
  return (
    <div className="settings-page">
      <UserAccountSettingsPage />
      <MembershipSettingsPage />
    </div>
  );
}

export default SettingsPage;
