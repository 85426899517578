import React from "react";
import IndexComponent from "../IndexComponent";

function ResourcesContentIndex() {
  const contentList = [
    {
      label: "How does it work?",
      link: "/content/resources/how-does-it-work",
    },
    {
      label: "F.A.Q.",
      link: "/content/resources/frequently-asked-questions",
    },
    { label: "Supported Jobs", link: "/content/resources/supported-jobs" },
    {
      label: "Supported Locations",
      link: "/content/resources/supported-locations",
    },
  ];

  return <IndexComponent title="Resources" contentList={contentList} />;
}

export default ResourcesContentIndex;
