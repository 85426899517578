import React from "react";
import { Link } from "react-router-dom";

const ActionButton = ({ className, onClick, label, disabled, ...rest }) => {
  return (
    <button
      className={className}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {label}
    </button>
  );
};

const LinkButton = (props) => {
  const onClick =
    "undefined" === typeof props.onClick
      ? () => undefined
      : () => props.onClick();

  return (
    <Link to={props.link} className={props.className} onClick={onClick}>
      {props.label}
    </Link>
  );
};

const Button = ({ role, link, className, ...rest }) => {
  const classes = `button button--${role || "neutral"}`;

  return "undefined" === typeof link ? (
    <ActionButton {...rest} className={`${classes} ${className}`} />
  ) : (
    <LinkButton {...rest} link={link} className={`${classes} ${className}`} />
  );
};

export default Button;
