import React from "react";
import ContentLayout from "../../layout/ContentLayout";

function TermsOfServiceContent() {
  return (
    <ContentLayout title="Terms of Service">
      <p>We are still working on this page! :)</p>
    </ContentLayout>
  );
}

export default TermsOfServiceContent;
