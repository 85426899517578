import React, { useEffect, useContext, useState } from "react";
import { UserContext } from "../../utils/context";
import {
  getAllCountries,
  getAllStatesOfCountry,
  getAllCitiesOfState,
} from "../../utils/location";
import Form, { FormField } from "../../components/form/Form";
import { CardStep } from "../Card";

function PrimaryLocationForm() {
  const { user, setUser } = useContext(UserContext);
  const idToken = user.auth.accessToken;
  const [allCountries, setAllCountries] = useState(null);
  const [allStates, setAllStates] = useState(null);
  const [allCities, setAllCities] = useState(null);

  const defaultSelect = { name: "-- select --", iso: "--" };

  useEffect(() => {
    getAllCountries(idToken).then((res) => setAllCountries(res.data.data));
  }, []);

  function update(key, value) {
    setUser((prev) => {
      const updatedData = {
        ...prev,
      };

      if (!updatedData?.primary_location) {
        updatedData.primary_location = {};
      }

      updatedData.primary_location[key] = value;

      return updatedData;
    });
  }

  function getCountryDropDownList() {
    if (!allCountries) {
      return <span>Loading the list of all countries...</span>;
    }

    function onChange(e) {
      const countryCode = e.target.value;
      update("country", countryCode);
      update("state", "--");
      update("city", "--");

      setAllStates(null);
      getStateDropDownList(true);

      setAllCities(null);
      getCityDropDownList(true);
    }

    const items = [defaultSelect, ...allCountries].map((d) => (
      <option value={d.iso} key={d.iso}>
        {d.name}
      </option>
    ));

    return (
      <select
        name="country"
        value={user?.primary_location?.country}
        onChange={onChange}
      >
        {items}
      </select>
    );
  }

  function getStateDropDownList(skipRender) {
    const countryCode = user?.primary_location?.country;

    if (!countryCode) {
      return <span>Select a country first.</span>;
    }

    function onChange(e) {
      update("state", e.target.value);
      update("city", "--");

      setAllCities(null);
      getAllCitiesOfState(true);
    }

    if (!allStates) {
      getAllStatesOfCountry(idToken, countryCode).then((res) =>
        setAllStates(res.data.data)
      );
    }

    if (skipRender) {
      return;
    }

    if (!allStates) {
      return <span>Loading list of all states</span>;
    }

    const items = [defaultSelect, ...allStates].map((d) => (
      <option value={d.iso} key={d.iso}>
        {d.name}
      </option>
    ));

    return (
      <select
        name="state"
        value={user?.primary_location?.state}
        onChange={onChange}
      >
        {items}
      </select>
    );
  }

  function getCityDropDownList(skipRender) {
    const countryCode = user?.primary_location?.country;
    const stateCode = user?.primary_location?.state;

    if (!countryCode || !stateCode) {
      return <span>Select a state first.</span>;
    }

    function onChange(e) {
      update("city", e.target.value);
    }

    if (!allCities) {
      getAllCitiesOfState(idToken, countryCode, stateCode).then((res) =>
        setAllCities(res.data.data)
      );
    }

    if (skipRender) {
      return;
    }

    if (!allCities) {
      return <span>Loading list of all cities</span>;
    }

    const items = [defaultSelect, ...allCities].map((d) => (
      <option value={d.name} key={d.name}>
        {d.name}
      </option>
    ));

    return (
      <select
        name="city"
        value={user?.primary_location?.city}
        onChange={onChange}
      >
        {items}
      </select>
    );
  }

  return (
    <CardStep>
      <Form>
        <FormField
          label="Country"
          desc="(Required)"
          input={getCountryDropDownList()}
        />
        <FormField label="State" input={getStateDropDownList()} />
        <FormField label="City" input={getCityDropDownList()} />
      </Form>
    </CardStep>
  );
}

export default PrimaryLocationForm;
