import React, { useEffect, useContext, useState } from "react";
import { UserContext } from "../../utils/context";
import Card, { CardStep } from "../Card";
import PrimaryLocationForm from "./PrimaryLocationForm";
import CurrentLocationForm from "./CurrentLocationForm";

function LocationDetailsForm() {
  const { user, setUser } = useContext(UserContext);
  const idToken = user.auth.accessToken;

  const cardStep3 = (
    <CardStep>
      <span>
        Entre details about your preferences regarding working worldwide and
        online.
      </span>
    </CardStep>
  );

  const cardSteps = [
    { title: "Primary Location", content: <PrimaryLocationForm /> },
    { title: "Current Location", content: <CurrentLocationForm /> },
    // { title: "Worldwide & Online", content: cardStep3 },
  ];

  return <Card steps={cardSteps} className="location-details-card" />;
}

export default LocationDetailsForm;
