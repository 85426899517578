import React from "react";
import IndexComponent from "../IndexComponent";

function HelpContentIndex() {
  const contentList = [
    { label: "Support", link: "/content/help/support" },
    { label: "Feedback", link: "/content/help/feedback" },
    { label: "Contact", link: "/content/help/contact-us" },
    { label: "About", link: "/content/help/about-us" },
  ];

  return <IndexComponent title="Help" contentList={contentList} />;
}

export default HelpContentIndex;
