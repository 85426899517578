import React, { useContext, useState } from "react";
import { UserContext } from "../../utils/context";
import Form, { FormField } from "../../components/form/Form";
import {
  validateContactName,
  validateContactEmail,
  validateYearOfBirth,
} from "../../utils/validate";
import Card, { CardStep } from "../Card";
import { allLangs } from "../../utils/lang";

function ContactDetailsForm({ formErrorRegister }) {
  const { user, setUser } = useContext(UserContext);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [yearOfBirthError, setYearOfBirthError] = useState(false);

  const nameErrorKey = "Contact > Name";
  const emailErrorKey = "Contact > Email";
  const yearOfBirthErrorKey = "About You > Year of Birth";

  function update(key, value) {
    setUser((prev) => {
      const update = {
        ...prev,
      };

      if (key == "name") {
        update.contact_details.name = value;

        const validatedName = validateContactName(value);

        if (validatedName === true) {
          setNameError(null);
          formErrorRegister(nameErrorKey, false);
        } else {
          setNameError(validatedName.error);
          formErrorRegister(nameErrorKey, validatedName.error.message);
        }
      }

      if (key == "email") {
        console.log("email cannot be modified right now");

        // update.contact_details.email = {
        //   address: value,
        //   verified: false,
        // }

        // const validatedEmail = validateContactEmail(value);

        // if (validatedEmail === true) {
        //   setEmailError(null);
        //   formErrorRegister(emailErrorKey, false);
        // } else {
        //   setEmailError(validatedEmail.error);
        //   formErrorRegister(emailErrorKey, validatedEmail.error.message);
        // }
      }

      return update;
    });
  }

  const nameInput = (
    <input
      type="text"
      name="name"
      value={user?.contact_details.name}
      onChange={(e) => update("name", e.target.value)}
    />
  );

  const emailInput = (
    <input
      type="text"
      name="email"
      value={user?.contact_details.email.address || user.auth?.email}
      onChange={(e) => update("email", e.target.value)}
      disabled={true}
    />
  );

  async function addLanguagesToProfile() {
    if (!user?.languages) {
      return setUser((prev) => {
        const update = { ...prev };
        update.languages = {
          1: { level: "", name: "" },
          2: { level: "", name: "" },
          3: { level: "", name: "" },
        };

        return update;
      });
    }
  }

  async function handleLangLevelChange(e, id) {
    const value = e.target.value;

    await addLanguagesToProfile();

    setUser((prev) => {
      const update = { ...prev };
      update.languages[id].level = value;

      return update;
    });
  }

  async function handleLangNameChange(e, id) {
    const value = e.target.value;

    await addLanguagesToProfile();

    setUser((prev) => {
      const update = { ...prev };
      update.languages[id].name = value;

      return update;
    });
  }

  function getLangInput(id) {
    const language =
      user?.languages && typeof user?.languages[id] != undefined
        ? user?.languages[id]
        : { name: "", level: "" };
    const langInput = (
      <div className="language-card">
        <select
          name={`level_${id}`}
          onChange={(e) => handleLangLevelChange(e, id)}
          className="language-card__level"
          value={language.level}
        >
          <option>Level</option>
          <option value="1">Beginner</option>
          <option value="2">Advanced</option>
          <option value="3">Fluent</option>
          <option value="4">Native</option>
        </select>
        {` `}
        <select
          name={`name_${id}`}
          onChange={(e) => handleLangNameChange(e, id)}
          className="language-card__name"
          value={language.name}
        >
          <option>--</option>
          {allLangs.map((lang) => (
            <option value={lang} key={lang}>
              {lang}
            </option>
          ))}
        </select>
      </div>
    );

    return langInput;
  }

  async function addPersonalDataToProfile() {
    if (!user?.personal_details) {
      return setUser((prev) => {
        const update = { ...prev };
        update.personal_details = {
          gender: "",
          yearOfBirth: "",
        };

        return update;
      });
    }
  }

  async function handleGenderChange(e) {
    const value = e.target.value;
    await addPersonalDataToProfile();

    setUser((prev) => {
      const update = { ...prev };
      update.personal_details.gender = value;

      return update;
    });
  }

  async function handleYearOfBirthChange(e) {
    const value = e.target.value;
    await addPersonalDataToProfile();

    setUser((prev) => {
      const update = { ...prev };
      update.personal_details.yearOfBirth = value;

      return update;
    });

    const validateYearOfBirthResults = validateYearOfBirth(value, true);

    if (validateYearOfBirthResults === true) {
      setYearOfBirthError(null);
      formErrorRegister(yearOfBirthErrorKey, false);
    } else {
      setYearOfBirthError(validateYearOfBirthResults.error);
      formErrorRegister(
        yearOfBirthErrorKey,
        validateYearOfBirthResults.error.message
      );
    }
  }

  const genderInput = (
    <select
      name="gender"
      value={user?.personal_details?.gender}
      onChange={handleGenderChange}
    >
      <option>--</option>
      <option value="female">Female</option>
      <option value="male">Male</option>
      <option value="other">Other</option>
      <option value="na">Prefer not to say</option>
    </select>
  );

  const yearOfBirthInput = (
    <input
      type="number"
      name="year_of_birth"
      value={user?.personal_details?.yearOfBirth}
      onChange={(e) => handleYearOfBirthChange(e)}
    />
  );

  // Cards
  const cardStep1 = (
    <CardStep>
      <Form className="first-child">
        <FormField label="Name" input={nameInput} error={nameError} />
        <FormField
          label="Contact Email [locked]"
          desc="(Required)"
          input={emailInput}
          error={emailError}
        />
      </Form>
    </CardStep>
  );

  const cardStep2 = (
    <CardStep>
      <Form>
        <FormField label="" input={getLangInput(1)} />
        <FormField label="" input={getLangInput(2)} />
        <FormField label="" input={getLangInput(3)} />
      </Form>
    </CardStep>
  );

  const cardStep3 = (
    <CardStep>
      <Form>
        <FormField label="Gender" input={genderInput} />
        <FormField
          label="Year of Birth"
          input={yearOfBirthInput}
          error={yearOfBirthError}
        />
      </Form>
    </CardStep>
  );

  const cardSteps = [
    { title: "Contact Details", content: cardStep1 },
    { title: "Languages", content: cardStep2 },
    { title: "About You", content: cardStep3 },
  ];

  return <Card steps={cardSteps} className="contact-details-card" />;
}

export default ContactDetailsForm;
