import React from "react";
import ContentLayout from "../../layout/ContentLayout";

function AboutUsContent() {
  return (
    <ContentLayout title="About Us">
      <p>We are still working on this page! :)</p>
    </ContentLayout>
  );
}

export default AboutUsContent;
