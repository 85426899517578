import React from "react";
import { useNavigate } from "react-router-dom";
import { handleGoogleLoginButton } from "../../utils/auth";
import Button from "../form/Button";

function AuthProviderButtons() {
  const navigate = useNavigate();

  function handleFailedLogin(error) {
    console.log("login failed:", error);
    return <h3>Login failed</h3>;
  }

  function handleAPILoginResponse(data) {
    if (!data) {
      handleFailedLogin();
    }
    if (data?.uid && data?.accessToken) {
      navigate("/app");
    }
  }

  function handleSubmit(provider) {
    if (provider == "google") {
      handleGoogleLoginButton()
        .then(handleAPILoginResponse)
        .catch((e) => handleFailedLogin(e));
    }
  }

  return (
    <div className="auth-provider-buttons">
      <h4>or choose another method &#8609;</h4>
      <div className="auth-provider-buttons__buttons">
        <Button
          label="Passwordless"
          onClick={() => navigate("/passwordless")}
        />
        <Button label="Google" onClick={() => handleSubmit("google")} />
      </div>
    </div>
  );
}

export default AuthProviderButtons;
