import { createContext } from "react";

export function getDefatulUserContextValue() {
  return {
    version: 1,
    lastUpdate: null,
    profileLoaded: false,
    emailVerified: false,
    auth: {
      uid: null,
      accessToken: null,
      email: null,
      name: null,
    },
    contact_details: {
      name: null,
      emaill: {
        address: null,
        verified: false,
      },
    },
    uid: null,
  };
}

export const UserContext = createContext(getDefatulUserContextValue());
