import axios from "axios";

// Get the list of all countries
// -----------------------------
export function getAllCountries(idToken) {
  return axios.post("https://getallcountriesv0-qxm247wkia-uc.a.run.app/", {
    idToken,
  });
}

// Get the list of all states, for a specific country
// --------------------------------------------------
export function getAllStatesOfCountry(idToken, countryCode) {
  return axios.post(
    "https://getstatesbycountrycodev0-qxm247wkia-uc.a.run.app/",
    {
      idToken,
      countryCode,
    }
  );
}

// Get the list of all cities, for a specific state in a country
// -------------------------------------------------------------
export function getAllCitiesOfState(idToken, countryCode, stateCode) {
  return axios.post("https://getcitiesofstatev0-qxm247wkia-uc.a.run.app/", {
    idToken,
    countryCode,
    stateCode,
  });
}
