import React from "react";
import ContentLayout from "../../layout/ContentLayout";

function HowDoesItWorkContent() {
  return (
    <ContentLayout title="How Does It Work?">
      <p>
        At <b>Freelancers Directory</b>, we’ve created a platform designed with
        the needs of freelancers and their clients at its core. Our approach is
        simple yet effective: to provide a space where freelancers can easily
        connect with opportunities and where those seeking freelance talent can
        find the perfect match without hassle.
      </p>

      <p>
        Our platform operates with a strong emphasis on privacy and efficiency.
        We understand that everyone wants control over their information, which
        is why your details remain completely confidential. Advertisers never
        access your personal data; instead, they filter through our directory
        based on specific criteria, such as location and skills. The only thing
        they see is the number of freelancers who match their search. This
        approach ensures that your privacy is always protected, and you only
        engage with opportunities that genuinely interest you.
      </p>

      <p>
        We believe in making things as effortless as possible for freelancers.
        Once you’ve signed up and completed your profile, there’s no need to
        constantly check the platform. We handle that for you. Whenever a job
        matches your criteria, we’ll send you an email with all the information
        you need to connect with the advertiser directly. This way, you can
        focus on your work while we keep an eye out for the right opportunities
        for you.
      </p>

      <p>
        For those looking to hire, the process is just as streamlined.
        Advertisers can refine their search criteria to pinpoint exactly the
        type of freelancer they need. Once they’re satisfied with the results,
        they can craft and send their ad directly to the freelancers who match
        their needs, without ever seeing personal details.
      </p>

      <p>
        As we continue to grow, we’re committed to expanding our features and
        reaching new areas of the freelance world. Our aim is to evolve
        alongside our users, ensuring that Freelancers Directory remains a
        valuable and trusted resource for both freelancers and those who work
        with them.
      </p>
    </ContentLayout>
  );
}

export default HowDoesItWorkContent;
