import React, { useEffect } from "react";
import { handleSignOut } from "../utils/auth";
import { useNavigate } from "react-router-dom";

function SignOutPage() {
  const navigate = useNavigate();

  useEffect(() => {
    handleSignOut();
    navigate("/");
  }, []);

  return <div>Siging you out!</div>;
}

export default SignOutPage;
