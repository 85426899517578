import React from "react";
import Card, { CardStep } from "../Card";
import PrimaryRolesCard from "./PrimaryRolesCard";
import SecondaryRolesCard from "./SecondaryRolesCard";

function RoleDetailsForm({ className }) {
  const cardStep3 = (
    <CardStep>
      <span>333</span>
    </CardStep>
  );

  const cardSteps = [
    { title: "Primary Roles", content: <PrimaryRolesCard /> },
    { title: "Secondary Roles", content: <SecondaryRolesCard /> },
    // { title: "Shiftwork", content: cardStep3 },
  ];

  return (
    <Card steps={cardSteps} className={`role-details-form ${className}`}></Card>
  );
}

export default RoleDetailsForm;
