function getError(error) {
  return Object.create(error);
}

const errorCodes = {
  NOT_PROCESSED: {
    code: "NOT_PROCESSED",
    message: "Not processed yet.",
  },
  STRING_IS_EMPTY: {
    code: "STRING_IS_EMPTY",
    message: "This field can't be empty.",
  },
  STRING_TOO_SHORT: {
    code: "STRING_TOO_SHORT",
    message: "The value is too short.",
  },
  IS_NOT_NUMBER: {
    code: "IS_NOT_NUMBER",
    message: "The value is not a number",
  },
  NUMBER_NOT_IN_RANGE: {
    code: "NUMBER_NOT_IN_RANGE",
    message: "The value is not in range",
  },
  INVALID_EMAIL: {
    code: "INVALID_EMAIL",
    message: "The email address is invalid.",
  },
};

function getEmptyResultObject(value) {
  return {
    passed: false,
    error: getError(errorCodes.NOT_PROCESSED),
  };
}

function evaluateTestResults(results) {
  for (let i = 0; i < results.length; i++) {
    const r = results[i];
    if (!r.passed) {
      return r;
    }
  }

  return true;
}

export function isNotEmpty(value) {
  const result = getEmptyResultObject(value);

  if (typeof value == "string") {
    if (0 >= value.trim().length) {
      result.error = getError(errorCodes.STRING_IS_EMPTY);
      return result;
    }
  }

  result.passed = true;
  return result;
}

export function isLongerThan(value, minLength, allowEmpty, message) {
  const result = getEmptyResultObject(value);

  if (allowEmpty && value.trim().length == 0) {
    result.passed = true;
    return result;
  }

  if (value.trim().length < minLength) {
    result.error = getError(errorCodes.STRING_TOO_SHORT);

    if (message) {
      result.error.message = message;
    }

    return result;
  }

  result.passed = true;
  return result;
}

export function isNumber(value, message) {
  const result = getEmptyResultObject(value);

  if (typeof value == "string") {
    if (!/^\d+$/.test(value.trim())) {
      result.error = getError(errorCodes.IS_NOT_NUMBER);

      if (message) {
        result.error.message = message;
      }

      return result;
    }
  }

  result.passed = true;
  return result;
}

export function numberIsBetween(value, rangeStart, rangeEnd, message) {
  const result = getEmptyResultObject(value);
  let asNumber = value;

  if (!typeof value == "number") {
    asNumber = parseInt(value);
  }

  if (asNumber < rangeStart || asNumber > rangeEnd) {
    result.error = getError(errorCodes.NUMBER_NOT_IN_RANGE);

    if (message) {
      result.error.message = message;
    }

    return result;
  }

  result.passed = true;
  return result;
}

export function isValidEmail(value) {
  const result = getEmptyResultObject(value);
  const test = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value);

  if (!test) {
    result.error = getError(errorCodes.INVALID_EMAIL);
    return result;
  }

  result.passed = true;
  return result;
}

export function validateContactName(value) {
  return evaluateTestResults([
    isLongerThan(
      value,
      2,
      true,
      "The name should be either empty or at least 2 characters long."
    ),
  ]);
}

export function validateContactEmail(value) {
  return evaluateTestResults([isNotEmpty(value), isValidEmail(value)]);
}

export function validateYearOfBirth(value, optional) {
  const currentYear = new Date().getFullYear();
  const rangeStart = currentYear - 80;
  const rangeEnd = currentYear - 18;

  if (optional && !value) {
    return true;
  }

  return evaluateTestResults([
    isNumber(value, "The 'Year of Birth' value should only contain digits."),
    numberIsBetween(
      value,
      rangeStart,
      rangeEnd,
      `The 'Year of Birth' value should be either empty or between ${rangeStart} and ${rangeEnd}.`
    ),
  ]);
}

// Check Password Strength
// -----------------------
export function checkPasswordStrength(password, setError) {
  const minLength = 6;
  const maxLength = 32;
  const mustHave = new RegExp(/[A-Za-z0-9]/i);

  if (!password || password.length < minLength) {
    setError(`Password must be at least ${minLength} characters.`);
    return false;
  }

  if (!password || password.length > maxLength) {
    setError(`Password must have fewer than ${maxLength} characters.`);
    return false;
  }

  if (!password.match(/[a-z]+/)) {
    setError(`Password must contain at least one lowercase [a-z] character.`);
    return false;
  }

  if (!password.match(/[A-Z]+/)) {
    setError(`Password must contain at least one uppercase [A-Z] character.`);
    return false;
  }

  if (!password.match(/[0-9]+/)) {
    setError(`Password must contain at least one [0-9] digit.`);
    return false;
  }

  setError(null);

  return true;
}
