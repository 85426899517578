import React from "react";

export function FormMessage({ children, type }) {
  return (
    <div className={`form__message form__message--${type}`}>{children}</div>
  );
}

export function FormField({ label, desc, input, error, className }) {
  const hasError = error ? "form__field__input--with-error" : "";

  const descElement = desc ? (
    <span className="form__field__label__desc">{desc}</span>
  ) : null;

  return (
    <div className="form__field">
      {label ? (
        <div className="form__field__label">
          {label} {descElement}
        </div>
      ) : (
        <div className="form__field__spacer" />
      )}
      <div className={`form__field__input ${hasError}`}>
        {input}
        <div className="form__field__input__error">
          {error && (error?.message || error)}
        </div>
      </div>
    </div>
  );
}

export function FormButtons({ children, className }) {
  return <div className={`form__buttons ${className}`}>{children}</div>;
}

function Form({ children, className, ...rest }) {
  return (
    <form {...rest} className={`form ${className}`}>
      {children}
    </form>
  );
}

export default Form;
