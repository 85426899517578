import React from "react";
import IndexComponent from "../IndexComponent";

function ServicesContentIndex() {
  const contentList = [
    { label: "Membership", link: "/content/services/membership" },
    { label: "Posting an Advert", link: "/content/services/posting-an-advert" },
    { label: "Pricing", link: "/content/services/pricing" },
  ];

  return <IndexComponent title="Services" contentList={contentList} />;
}

export default ServicesContentIndex;
