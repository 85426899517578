import React from "react";
import WebsiteSectionWrapper from "../layout/WebsiteSectionWrapper";
import { Link } from "react-router-dom";

function IndexComponent({ title, contentList }) {
  return (
    <WebsiteSectionWrapper className="first-child">
      <h4>{title}</h4>
      <ul>
        {contentList.map(({ label, link }) => (
          <li>
            <Link to={link}>{label}</Link>
          </li>
        ))}
      </ul>
    </WebsiteSectionWrapper>
  );
}

export default IndexComponent;
