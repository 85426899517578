import React from "react";
import WebsiteSectionWrapper from "../layout/WebsiteSectionWrapper";
import Button from "../components/form/Button";

function HomePage() {
  return (
    <>
      <div className="homepage-launch-banner">
        <WebsiteSectionWrapper>
          <h2>
            We are launching Freelancers Directory on 15th of October, 2024!
          </h2>
          <p>
            Freelancers, it's time to prepare for an easier life! Our platform
            is now open for sign-ups, giving you the chance to create your
            profile and get job notifications right into your inbox, without
            ever having to sign in again!
            <br />
            <br />
            Freelancer search and job posting features will go live on{" "}
            <b>October 15th, 2024</b>, so sign up now to be among the first to
            seize new opportunities as they become available!
          </p>
          <h4>Sign Up Today, Jobs Coming Soon!</h4>
          <Button
            label="Sign-up as a Freelancer"
            link="/sign-up"
            role="primary"
          />
        </WebsiteSectionWrapper>
      </div>
      <WebsiteSectionWrapper className="home-page first-child">
        <h4>Welcome to</h4>
        <h1>Freelancers Directory</h1>
        <p>
          A platform made by freelancers, for freelancers. Whether you're
          looking to land gigs or hire top talent, we've got you covered.
        </p>

        <ul>
          <li>
            <h3>We Get You</h3>
            <p>
              As freelancers ourselves, we know the challenges and stress of
              constantly searching for work or finding the right collaborators.
              That’s why we built our platform—to make life easier for
              freelancers and the clients who want to hire them.
            </p>
          </li>
          <li>
            <h3>Total Privacy</h3>
            <p>
              Your privacy is our priority. All of your information stays with
              you—none of your details are ever shared with advertisers or third
              parties. Advertisers don’t need access to your personal info.
              Instead, they filter through our directory by setting specific
              criteria, and we show them only the number of freelancers that
              match. From there, they can go ahead and send their ad to those
              selected freelancers, without ever having to access their details.
            </p>
          </li>
          <li>
            <h3>Set & Forget</h3>
            <p>
              Once you sign up and complete your profile, you’re all set. You
              won’t need to sign in constantly to check for opportunities or
              communicate with the advertisers. We’ll send you an email whenever
              a relevant ad matches your criteria. The email will include all
              the details you need to get in touch with the advertiser directly
              e.g. via phone or email, thus, saving you time and effort.
            </p>
          </li>
          <li>
            <h3>Smart Search for Advertisers</h3>
            <p>
              Advertisers can quickly find the right freelancers by setting
              criteria like location and experience. We’ll show them how many
              freelancers fit the bill, and they can adjust the search until
              they’re happy. Once that’s done, they just craft the ad and hit
              send!
            </p>
          </li>
          <li>
            <h3>We Keep It Focused</h3>
            <p>
              We’re not trying to be everything to everyone. Instead, we
              specialise in a few freelance niches, making sure we’re the best
              at what we do for the people who trusted us. That’s why we’re
              currently limiting sign-ups to freelancers in mostly Creative
              industry. While we plan to expand into other fields in the future,
              our priority for now is to be the best for a smaller group rather
              than just okay at covering multiple areas.
            </p>
          </li>
        </ul>

        <p>
          And we’re just getting started—more features are on the way to make
          Freelancers Directory even better for you!
        </p>
        {/*
        <br />
        <br />
        <h2>A) Get relevant job alerts in your inbox.</h2>
        <p>No need to sign-in agian! You will get every</p>
        <h2>B) Email freelancers indirectly through us.</h2>
        */}
      </WebsiteSectionWrapper>
    </>
  );
}

export default HomePage;
