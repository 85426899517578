import React, { useState } from "react";
import Form, {
  FormButtons,
  FormField,
  FormMessage,
} from "../components/form/Form";
import Button from "../components/form/Button";
import { changePassword, handleAuthError } from "../utils/auth";
import { checkPasswordStrength } from "../utils/validate";

function UserAccountSettingsPage() {
  const [pass, setPass] = useState("");
  const [passError, setPassError] = useState(null);
  const [rePass, setRePass] = useState("");
  const [formError, setFormError] = useState(null);
  const [formMessage, setFormMessage] = useState(null);
  const [disable, setDisable] = useState(false);

  function handleChangePasswordSubmit(e) {
    e.preventDefault();

    setFormError(null);

    if (!pass || !rePass) {
      setPassError("");
      setFormError("Both Password & Repeat Password fields are required.");
      return false;
    }

    if (pass != rePass) {
      setPassError("");
      setFormError("Password and Repeat Password do not match.");
      return false;
    }

    if (!checkPasswordStrength(pass, setPassError)) {
      return false;
    }

    setDisable(true);

    changePassword(pass)
      .then(() => {
        // console.log("change pass success");
        setFormError(null);
        setPass("");
        setRePass("");
        setFormMessage("Password Changed Successfully.");
        setDisable(false);
      })
      .catch((authError) => {
        // console.log(authError?.code, authError);
        handleAuthError(authError, setFormError);
        setDisable(false);
      });
  }

  const passInput = (
    <input
      type="password"
      value={pass}
      onChange={(e) => setPass(e.target.value)}
    />
  );
  const rePassInput = (
    <input
      type="password"
      value={rePass}
      onChange={(e) => setRePass(e.target.value)}
    />
  );

  return (
    <div className="settings-section account-settings-section">
      <div className="settings-section__header">User Account</div>
      <div className="settings-section__content">
        <Form onSubmit={handleChangePasswordSubmit}>
          {formMessage && (
            <FormMessage type="success">
              <span>{formMessage}</span>
            </FormMessage>
          )}
          {formError && (
            <FormMessage type="error">
              <span>{formError}</span>
            </FormMessage>
          )}
          <h4>Change Password</h4>
          {passError && (
            <p>
              Passwords must be:
              <ul>
                <li>
                  At least <b>6</b> characters long.
                </li>
                <li>
                  Not longer than <b>32</b> characters.
                </li>
                <li>
                  Contain at least one lowercase <b>[a-z]</b> character.
                </li>
                <li>
                  Contain at least one uppercase <b>[A-Z]</b> character
                </li>
                <li>
                  Contain at least one <b>[0-9]</b> digit.
                </li>
              </ul>
            </p>
          )}
          <FormField label="New Password" input={passInput} error={passError} />
          <FormField label="Repeat Password" input={rePassInput} />
          <p>
            Please Note: If you did not sign-up using email and password method
            e.g. via Google or Passwordless sign-up methods, then using this
            form will create a password for your account. This means you can now
            sign-in using email and password, as well as your original method of
            sign-up.
          </p>
          <FormButtons>
            <Button
              label={disable ? "Saving..." : "Save Password"}
              type="submit"
              role="primary"
              disabled={disable}
            />
          </FormButtons>
        </Form>
      </div>
    </div>
  );
}

export default UserAccountSettingsPage;
